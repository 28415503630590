import React, { Component } from "react"
import Select from "react-select/async"
import searchIcon from "../assets/mag_glass.png"
import clearIcon from "../assets/clear.png"
import "./Dashboard.css"
import { filterByLastSix, user, challenges, deregisterUser } from "../services"
import { Themes } from "../managers/ThemeManager"
import { Constants } from "../Constants"
import { withPermissions } from "../components/AuthenticationComponent"

const customStyles = {
  control: (previous) => ({
    ...previous,
    minWidth: "30vw",
    backgroundColor: "#000",
    border: "0 solid transparent",
    borderBottom: "1px solid #fff",
    boxShadow: "none",
    borderRadius: 0
  }),
  input: (previous) => ({
    ...previous,
    color: "#fff"
  }),
  singleValue: (previous) => ({
    ...previous,
    color: "#fff"
  }),
  option: (previous, { isSelected }) => {
    const colors = Themes.currentTheme().colors
    return {
    ...previous,
    backgroundColor: "transparent",
    color: isSelected ? colors.buttonText : null,
    ":hover": {
      backgroundColor: colors.accect1 + "dd"
    }}
  },
  valueContainer: (previous) => ({
    ...previous,
    color: "#fff"
  })
}

const DataPoints = (props) => {
  return (
    <div className="flex">
      <div className="subHeader">{props.label}</div>
      <div>{props.data || "-"}</div>
    </div>
  )
}

const DeregistrationDataPoints = (props) => {
  return (
    <div id="optionAction" onChange={props.onChange}>
      <input type="radio" value={props.value} name="reason for deregistering" id="dot"></input>
      {props.value}
    </div>
  )
}

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      haveData: false,
      deregReason: "",
      challenges: [],
      userInfo: {
        currentDevice: {}
      },
      showDeregModal: false,
      showLoader: false,
      searchResults: [],
      searchTerm:""
    }
  }

  showModal = () => {
    this.setState({ showDeregModal: true })
  }

  hideModal = () => {
    this.setState({ showDeregModal: false })
  }

  fetchUserList = async (searchTerm) => {
    if (searchTerm.length > 6) {
      return this.state.searchResults.filter((res) => {return res.label.startsWith(searchTerm)})
    } else if (searchTerm.length > 4) {
      try {
        let response = await filterByLastSix(searchTerm)
        const searchResults = Object.keys(response.users).map((userKey) => {
          return { value: response.users[userKey], label: userKey }
        }).sort((a,b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0)

        this.setState({ searchResults, searchTerm })
        return searchResults
      } catch (err) {
        this.setState({ showLoader: false, searchResults: [], searchTerm: "" }, () => {
          window.alert("Network Error: ", err.message)
        })
      }
    } else {
      return []
    }
  }

  onChangeReason = (event) => {
    let reason = event.target.value
    this.setState({ deregReason: reason })
  }

  deregister = () => {
    const regKey = this.state.userInfo.registration_key
    this.setState({ showLoader: true }, async () => {
      await deregisterUser(regKey, this.state.deregReason)
        .then((response) => {
          if (response.code !== "1200") {
            throw response
          }
          this.setUserInfo(regKey)
        })
        .catch((err) => {
          this.setState({ showLoader: false }, () => {
            window.alert("Network Error: ", err.message)
          })
        })
    })
  }

  setUserInfo = async (regKey) => {
    this.setState({ showLoader: true }, async () => {
      await user(regKey)
        .then((response) => {
          const currentDevice = response.userInfo.devices
            ? response.userInfo.devices.find(
                (device) => device.device_id === response.userInfo.device_id
              ) || {}
            : {}
          this.setState({ userInfo: response.userInfo, currentDevice })
        })
        .catch((err) => {
          this.setState({ showLoader: false }, () => {
            window.alert("Network Error: ", err.message)
          })
        })
      await challenges(regKey)
        .then((response) => {
          this.setState({
            challenges: response.challenges,
            showDeregModal: false,
            showLoader: false
          })
        })
        .catch((err) => {
          this.setState({ showLoader: false }, () => {
            window.alert("Network Error: ", err.message)
          })
        })
    })
  }

  onUserSelected = async (option) => {
    if (option && option.value) {
      await this.setUserInfo(option.value)
    }
  }

  render() {
    const images = Themes.currentTheme().images

    let currentDevice = this.state.currentDevice || {}

    let deregDevices = (this.state.userInfo.devices || []).filter((device) => {
      return device.registration_status === "DEREGISTERED"
    })

    let lastDeregDevice =
      deregDevices.sort((deviceA, deviceB) => {
        return Date.parse(deviceB.updated_at) - Date.parse(deviceA.updated_at)
      })[0] || {}

    let lastChallengeResponse = [...this.state.challenges]
      .sort((challengeA, challengeB) => {
        return Date.parse(challengeB.sent_at) - Date.parse(challengeA.sent_at)
      })
      .filter((challenge) => {
        let currentDeviceId = this.state.currentDevice.device_id || lastDeregDevice.device_id
        return challenge.device_id === currentDeviceId
      })[0]

    return (
      <>
        <div>
          <img src={images.banner} alt="Army MobileConnect Dashboard" id="bannerImage" />
          <div id="searchContainer">
            <div id="searchLabel">FIND A USER</div>
            <Select
              loadingMessage={({ inputValue }) => {
                return inputValue.length < 5 ? "Type at least 5 characters to see matches" : null
              }}
              noOptionsMessage={({ inputValue }) => {
                return inputValue.length < 5
                  ? "Type at least 5 characters to see matches"
                  : "No matching users found"
              }}
              placeholder="username"
              value={this.state.searchTerm}
              onChange={this.onUserSelected}
              defaultOptions={[]}
              options
              loadOptions={this.fetchUserList}
              styles={customStyles}              
              isClearable
              components={{
                DropdownIndicator: (options) => {
                  return options.hasValue ? null : (
                    <img src={searchIcon} alt="search icon" className="iconImages" />
                  )
                },
                IndicatorSeparator: () => {
                  return null
                },
                ClearIndicator: (options) => {
                  return (
                    <img
                      onClick={options.clearValue}
                      src={clearIcon}
                      alt="clear icon"
                      className="iconImages"
                    />
                  )
                }
              }}
            />
          </div>
          {!this.state.userInfo.registration_key ? null : (
            <div id="bodyContent">
              <h1>USER INFORMATION</h1>
              <div className="subBodyContent">
                <DataPoints data={this.state.userInfo.email_address} label="Email" />
                <DataPoints
                  data={this.state.userInfo.first_name + " " + this.state.userInfo.last_name}
                  label="Name"
                />
                <div className="flex" style={{ display: "flex" }}>
                  <div>
                    <div className="subHeader">Status</div>
                    <div>
                      {currentDevice.registration_status ||
                        `Deregistered as of ${new Date(
                          lastDeregDevice.deregistered_at
                        ).toString()}`}
                    </div>
                  </div>
                  {!this.state.currentDevice.device_id ? null : (
                    <div onClick={this.showModal} className="deregButton">
                      DEREGISTER
                    </div>
                  )}
                </div>
              </div>
              <div className="subBodyContent">
                <DataPoints data={this.state.currentDevice.device_name} label="Device name" />
                <DataPoints data={this.state.currentDevice.platform} label="Platform" />
                <DataPoints data={this.state.currentDevice.app_version} label="App Version" />
              </div>
              {lastChallengeResponse && (
                <>
                  <h1 className="marginT">LAST AUTHENTICATION ATTEMPT</h1>
                  <div className="subBodyContent">
                    <DataPoints
                      data={new Date(lastChallengeResponse.responded_at).toString()}
                      label="Date/Time"
                    />
                    <DataPoints
                      data={lastChallengeResponse.response_status}
                      label="Approved/Denied"
                    />
                    <DataPoints
                      data={lastChallengeResponse.deny_message}
                      label="Reason for denial"
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {!this.state.showDeregModal ? null : (
            <div id="modalContainer">
              <div id="modal">
                <h3 className="marginL">Deregister {this.state.userInfo.email_address}?</h3>
                <div className="marginL marginB">Reason (optional)</div>
                <DeregistrationDataPoints onChange={this.onChangeReason} value="Lost device" />
                <DeregistrationDataPoints onChange={this.onChangeReason} value="Stolen device" />
                <DeregistrationDataPoints
                  onChange={this.onChangeReason}
                  value="Transferring service to a new device"
                />
                <DeregistrationDataPoints
                  onChange={this.onChangeReason}
                  value="No longer needs access (e.g. leaving Army)"
                />
                <DeregistrationDataPoints
                  onChange={this.onChangeReason}
                  value="No longer wishes to use service"
                />
                <DeregistrationDataPoints value="Other" />
                <input type="text" id="textInput" onChange={this.onChangeReason} />
                <div id="buttonContainer">
                  <div id="cancelButton" onClick={this.hideModal}>
                    CANCEL
                  </div>
                  <div className="deregButton" onClick={this.deregister}>
                    YES,DEREGISTER
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={`loaderContainer ${this.state.showLoader ? "" : "hide"}`}>
          <div className="loader" />
        </div>
      </>
    )
  }
}

export default withPermissions(Dashboard, [Constants.PERMISSION_TYPES.AUTHENTICATED, Constants.PERMISSION_TYPES.ADMINISTRATOR])
