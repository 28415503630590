import React, { Component } from "react"
import "./Login.css"
import OmniAural from "omniaural"
import { Themes } from "../managers/ThemeManager"
import { logout } from "../services"
import LoadingSpinner from "../components/LoadingSpinner"
import { Constants } from "../Constants"

class Logout extends Component {
  constructor() {
    super()
    const images = Themes.currentTheme().images
    this.state = {
      errorCode: null,
      message: "",
      currentImage: 0,
      images: [
        images.loginBackground1,
        images.loginBackground2,
        images.loginBackground3,
        images.loginBackground4,
        images.loginBackground5
      ],
      loading: true
    }
  }

  themeStrings = {
    army: {
      appName: "Army MobileConnect app",
      enterpriseAbbrev: "EAMS-A"
    },

    airforce: {
      appName: "Air Force MobileConnect app",
      enterpriseAbbrev: "XYZ-PDQ"
    }
  }

  switchImage = () => {
    if (this.state.currentImage < this.state.images.length - 1) {
      this.setState({
        currentImage: this.state.currentImage + 1
      })
    } else {
      this.setState({
        currentImage: 0
      })
    }
  }

  componentDidMount() {
    console.log("Logout log")
    setInterval(this.switchImage, 4000)
    window.addEventListener("beforeunload", (e) => {
      e.preventDefault()
      this.props.cookies.remove(Constants.COOKIE_ID)
    })

    const uid = OmniAural.state.user?.uid?.value()
    logout(uid)
      .then((response) => {
        OmniAural.clearProperty("user")
        if (!!response.sso_logout_url) {
          window.location.assign(response.sso_logout_url)
        } else {
          this.setState({ loading: false })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />
    }

    return (
      <div className="login">
        <div id="wrap">
          <div id="background-image"></div>

          <div id="logo-image-container">
            <img
              id="logo-image"
              src={Themes.currentTheme().images.eamsImage}
              alt="EAMS-A logo"></img>
          </div>

          <div id="content">
            <h2>
              {`${Themes.currentTheme().strings.enterpriseAbbrev} Login`}
              <div id="mobile-newline"></div>Single Sign-On
            </h2>
            <div className="login-error">
              You have logged out successfully <br />
              Please close your browser window to end your session
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Logout
